.swagger-editor {
  &__editor-pane {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  &__editor-container {
    display: flex;
    width: 100%;
    flex-grow: 1;
    position: relative;
  }

  &__editor-pane-container-row {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
  }

  &__editor-pane-container-col {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}

.swagger-editor {
  &__validation-table {
    height: 100%;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 300;
    border: none;
    border-collapse: collapse;

    thead {
      color: #444;
      background: rgb(238, 238, 238);
      border-bottom: 1px solid lightgray;
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
      outline: none;
    }

    thead tr {
      display: block;
    }

    thead th {
      min-width: 2.0rem;
      padding: 2px 2px 2px 0.25rem;
      text-align: left;
    }


    thead th:nth-of-type(1), thead th:nth-of-type(2){
      text-align: center;
      width: 70px;
    }

    thead th:nth-of-type(3) {
      text-align: left;
      width: 100px;
    }

    tbody {
      display: block;
      width: 100%;
      height: 100px;
      max-height: 100px;
      overflow: auto;
    }

    tbody tr {
      width: 100% !important;
    }

    tbody tr:hover {
      background-color: #e1e1e1;
    }

    tbody td {
      cursor: pointer;
      min-width: 2.0rem;
      padding: 2px 2px 2px 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    tbody td:nth-of-type(1), tbody td:nth-of-type(2) {
      text-align: center;
      width: 70px;
    }

    tbody td:nth-of-type(3) {
      width: 100px;
    }

    tbody td:nth-of-type(3) {
      font-family: monospace;
      color: gray;
    }
  }
}

.swagger-editor {
  &__editor-textarea {
    border: 0;
    display: block;
    width: 100%;
    outline: 0;
    resize: none;
    flex-grow: 1;

    &:focus {
      outline: 0;
    }
  }
}

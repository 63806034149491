.swagger-editor__editor-preview-api-design-systems .summary-pill {
  background-color: #a004bf !important;
}

.swagger-editor__editor-preview-api-design-systems .model-box {
  background: rgba(0,0,0,.1) !important;
}

.swagger-editor__editor-preview-api-design-systems table.ads-principles tbody tr td:first-of-type {
  padding-left: 0;
}

.swagger-editor__editor-preview-api-design-systems .value-pill {
  background-color: rgb(127, 156, 245);
  border-bottom-color: rgb(203, 213, 224);
  border-bottom-left-radius: 4px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 700;
}

@import '@asyncapi/react-component/styles/default.min.css';

.swagger-editor {
  &__editor-preview-asyncapi-parse-errors {
    .swagger-editor__validation-table {
      tbody {
        max-height: none;
      }
    }
  }
}

.swagger-editor {
  &__layout {

    .SplitPane {
      height: #{"calc(100% - 54px)"} !important;
    }

    .Pane {
      overflow-y: scroll;
      background-color: #FFFFFF;
    }

    .dropzone {
      height: 100%;
      width: 100%;

      .dropzone__overlay {
        padding-top: 20px;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        background: #2D2D2D;
        text-align: center;
        color: #fff;
        font-size: 1rem;
      }
    }

    .btn-theme-icon {
      background-color: transparent;
      display: inline;
      line-height: 1;
      border: 1px solid;
      border-radius: 4px;
      border-color: white;
      color: #3b4151;
      padding: 0.36rem 0.36rem;
      transition: all .3s;
    }

    .swagger-ui {
      // ensure Swagger-UI uses its entire container
      height: 100%;

      .version-pragma {
        // make the version pragma message look nicer in the context
        // of the Editor
        font-size: 1.0em;
      }
    }

    @media print {
      //List of elements that should not be rendered for printing
      .topbar {
        display: none;
      }

      .SplitPane {
        position: relative !important;
        display: block !important;
      }

      //The right-hand preview pane should be the full width of the page
      .Pane {
        overflow-y: auto;
        width: 100% !important;
      }
    }

    .swagger-editor__validation-pane {
      overflow-y: hidden;
      max-height: 120px;
    }
  }
}
